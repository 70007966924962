import React from 'react'
import {publicUrl} from '../../configuration'
import imgTranscription from '../../images/transcription.svg'
import styled from 'styled-components'

const TranscriptionPageDefault = ({user, closeChat, sendTranscriptionRequest}) => (
  <>
    <TranscriptionImage src={publicUrl + imgTranscription} alt="transcription"/>
    <Text>Vuoi ricevere la trascrizione completa della chat con l'operatore all'indirizzo seguente?</Text>
    <Email>{user.email} </Email>
    <ButtonsContainer>
      <Button onClick={sendTranscriptionRequest} type="accept">Si, invia</Button>
      <Button onClick={closeChat}>No, grazie</Button>
    </ButtonsContainer>
  </>
)

export default TranscriptionPageDefault

// region STYLE

const TranscriptionImage = styled.img`
  margin-top: 20px;
`

const Text = styled.div`
  margin-top: 50px;
  font-size: 0.95rem;
  opacity: 0.8;
`

const Email = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  opacity: 0.8;
`

const ButtonsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Button = styled.div`
  margin: 10px;
  cursor: pointer;
  padding: 10px 15px;
  background: ${props => props.type === 'accept' 
    ? props.theme.transcription.buttonAcceptBackground 
    : props.theme.transcription.buttonRefuseBackground};
  color:  ${props => props.type === 'accept'
    ? props.theme.transcription.buttonAcceptColor
    : props.theme.transcription.buttonRefuseColor};
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: ${props => props.type === 'accept'
    ? props.theme.transcription.buttonAcceptFontSize
    : props.theme.transcription.buttonRefuseFontSize};
  font-weight: ${props => props.type === 'accept'
    ? props.theme.transcription.buttonAcceptFontWeight
    : props.theme.transcription.buttonRefuseFontWeight};
`

//endregion
