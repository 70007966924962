import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { mediaRequestAccepted, closeMediaCall } from '../../actions/socketActions'
import { ButtonAccept, ButtonRefuse } from '../common'
import screenfull from 'screenfull'

const mapDispatchToProps = dispatch => ({
  mediaRequestAccepted : (accept, type) => dispatch(mediaRequestAccepted(accept, type)),
  closeMediaCall : () => dispatch(closeMediaCall())
})

class MediaVideoChat extends Component {

  state = {
    isFullScreen: false
  }

  componentDidMount() {
    this.props.mediaRequestAccepted(true, 'video')
  }

  closeMedia = () => {
    this.setState({isFullScreen: false})
    this.props.closeMediaCall()
  }

  fullscreen = () => {
    if(screenfull.isEnabled){
      if (this.state.isFullScreen) {
        this.setState({isFullScreen: false})
        screenfull.exit()
      } else {
        this.setState({isFullScreen: true})
        screenfull.request(document.getElementById('video-container'))
      }
    }
  }

  render() {
    return(
      <>
        <MediaVideoContainer id='video-container'>
          <VideoOperator isFullScreen={this.state.isFullScreen} id='video-operator' autoPlay />
          <LocalVideoAndButtonContainer>
            <VideoCustomer id='video-customer' autoPlay muted={'muted'} />
            <ButtonContainer>
              <ButtonAccept onClick={this.fullscreen}>Full Screen</ButtonAccept>
              <ButtonRefuse onClick={this.closeMedia}>Chiudi</ButtonRefuse>
            </ButtonContainer>
          </LocalVideoAndButtonContainer>
        </MediaVideoContainer>
      </>
    )
  }
}

/********** region ~~~~~ STYLE ~~~~~ */

const MediaVideoContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const LocalVideoAndButtonContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 5px 10px;
`;

const ButtonContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 240px;
`;

const VideoOperator = styled.video `
  width: 100%;
  height: ${props => props.isFullScreen ? '100%' : '285px'};
`;

const VideoCustomer = styled.video `
  height: 110px;
  width: 110px;
`;

/********** endregion */

export default connect(null, mapDispatchToProps)(MediaVideoChat)

